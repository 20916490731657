.wrapper {
  display: flex;
  align-items: center;
  border-radius: 3rem;
  width: fit-content;
}

.image {
  margin: 0 0.625rem 0 0;
  overflow: hidden;
  max-width: 1.875rem;
  max-height: 1.875rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

.name {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.3;
  letter-spacing: -0.03em;
}

@media (max-width: 62rem) {
  .name {
    font-size: 0.75rem;
  }
}
