.row {
  display: flex;
  gap: 1.375rem;

  img {
    transition: 0.25s ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.title {
  margin: 0.625rem 0 0;

  letter-spacing: -0.03em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  span {
    border-bottom: 1px solid transparent;
  }
}

.date {
  opacity: 0.6;
}

.imageWrap {
  display: block;
  overflow: hidden;
  width: 6.25rem;
  height: 6.25rem;
  flex-shrink: 0;
}

.imageMobile {
  display: none;
}

.image {
  width: 6.25rem;
  height: 6.25rem;
}

.image img {
  height: 100%;
}

@media (min-width: 62.0001rem) {
  .imageWrap {
    transform: translate3d(0, 0, 0);
    will-change: transform;
  }

  .image,
  .title span {
    transition: 0.25s ease-out;
  }
  .row:hover {
    .title span {
      transition: 0.2s ease-in;
      border-bottom-color: inherit;
    }
  }
}

@media (max-width: 62rem) {
  .imageWrap {
    width: 4.6875rem;
    height: 4.6875rem;
  }

  .image {
    width: 4.6875rem;
    height: 4.6875rem;
  }
}
