.outer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grid {
  position: relative;
  align-content: flex-start;
  display: flex;
  flex-direction: column;

  img {
    transition: transform 0.5s;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.linkWrapper {
  position: relative;
}

.leftRow {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.imageFigure {
  display: block;
  overflow: hidden;
  margin: 0;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 1.19 / 1;
}

.tags {
  width: fit-content;
  position: absolute;
  top: 1.5625rem;
  left: 1.5625rem;
  padding: 0.125rem 0.3125rem 0.25rem 0.3125rem;
  background: var(--white);
  color: var(--black-1e);

  a {
    color: var(--black-1e);
  }
}

.titleLink {
  display: block;
}

.title {
  margin: 0;
  color: var(--black-1e);
  max-width: 13.0666em;
  font-weight: 500;
  // main news highlighting indentation
  background: var(--white);
  padding: 0.22rem 0 0.28rem 0;
  display: inline;
}

.line {
  background: var(--white);
  padding: 0.1875rem 0;
  // main news highlighting indentation
  position: relative;
  left: -0.625rem;
}

.description {
  margin: 1.5625rem 0 0 0;
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.31;
  letter-spacing: -0.03em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.25rem;
  margin-top: auto;
  border-top: 1px dashed var(--dashed);
}

.dateWithTime {
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 2.25rem;
}

.date {
  position: relative;

  &::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    background: var(--text);
    position: absolute;
    right: -1.3125rem;
    top: 0.3125rem;
  }
}

span.dateMobile {
  display: none;

  &::after {
    display: none;
  }
}

.imageWrapper {
  position: relative;
}

.imageInner {
  position: absolute;
  left: 1.5625rem;
  bottom: 1.5625rem;
  right: 1.5625rem;
  // main news highlighting indentation
  padding: 0.125rem 0;
  border-left: 1.25rem solid var(--white);
}

.counter {
  border-left: 1px solid var(--black-1e);
  padding-left: 20px;
}

.bottomMobile {
  display: none;
}

@media (max-width: 62rem) {
  .outer {
    width: 100%;
  }
  .titleLink {
    margin-bottom: 0.375rem;
  }

  .dateWithTime {
    justify-self: start;
  }

  .date,
  .description {
    display: none;
  }

  .dateMobile {
    display: block;
    margin-top: 1.25rem;
  }

  .bottom {
    display: none;
  }

  .bottomMobile {
    margin-top: 1.25rem;
    padding-top: 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px dashed var(--dashed);

    & > * {
      flex: 1 1 100%;
    }
  }

  .counter {
    border-left: none;
    padding-left: 0;
    justify-content: center;
    padding: 0 0.9375rem;
  }

  .readingTime {
    text-align: right;
    padding-left: 0.9375rem;
  }

  .author {
    padding-right: 0.9375rem;
  }

  .readingTime,
  .counter {
    border-left: 1px solid var(--text);
  }

  span.dateMobile {
    display: block;

    &::after {
      display: none;
    }
  }
  .title {
    padding: 0.2rem 0 0.22rem 0;
  }
  .imageInner {
    left: 0.9rem;
    bottom: 0.9rem;
  }
}
