.grid {
  position: relative;
  display: grid;
  margin-bottom: 4.375rem;
  padding-top: 1.5625rem;
  padding-bottom: 2.25rem;
  gap: 0 min(3.4722vw, 50px);
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'main main main news news articles';
  border-top: 1px solid var(--border);

  &.borderNone {
    border: none;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    height: 100%;
    width: 1px;
    background: var(--news-border);
    content: '';
  }

  &:before {
    right: 50%;
  }

  &:after {
    right: calc(
      ((100% - (min(3.4722vw, 50px) * 5)) / 6) + min(3.4722vw, 50px) / 2
    );
  }
}

.main {
  grid-area: main;
  display: flex;
}

.news {
  grid-area: news;
}

.articles {
  grid-area: articles;
}

.news,
.articles {
  display: flex;
  flex-direction: column;
}

.listNews,
.listArticles {
  margin: 1.5625rem 0 0;
  padding: 0;
  list-style: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listNews {
  li:not(:last-child) {
    margin-bottom: 0.9375rem;
  }
}

.listArticles {
  li:not(:last-child) {
    margin-bottom: 1.5625rem;
  }

  li:nth-child(n + 4) {
    display: none;
  }
}

.lineBottom {
  position: absolute;
  bottom: -3.125rem;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--border);
}

.articlesCard {
  h3 {
    margin-top: 0.625rem;
  }
}

@media (max-width: 75rem) {
  .articlesCard {
    p span {
      word-break: break-word;
    }
  }
}

@media (max-width: 62rem) {
  .grid {
    display: block;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: 0.25rem solid var(--border);
    margin-bottom: 0;

    &:before,
    &:after {
      display: none;
    }

    &--withSpecial {
      padding-top: 0;
    }
  }

  .main,
  .news {
    padding-bottom: 1.25rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--border);
  }

  .news {
    padding-bottom: 1.75rem;
  }

  .listArticles {
    display: grid;
    gap: 1.875rem 0.9375rem;
    grid-template-columns: 1fr 1fr;

    li:not(:last-child) {
      margin-bottom: 0;
    }

    li:nth-child(n + 4) {
      display: block;
    }

    li:nth-child(n + 5) {
      display: none;
    }
  }

  .listNews {
    li:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  .lineBottom {
    display: none;
  }

  .listArticlesDefault {
    display: grid;
  }

  .listArticlesSlice {
    display: none;
  }
}
