.container {
  position: relative;
  width: calc(100% + 2.5rem);
  margin: 0 0 -2.25rem -2.5rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    background-color: var(--background);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    background-color: var(--background);
  }
}

.image {
  z-index: -1;
  object-fit: cover;

  &--mobile {
    opacity: 0;
    object-position: top;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  color: var(--white);
  line-height: 1.3;
}

.tag {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--white);

  svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.625rem;
  }
}

.arrow {
  width: 0.375rem;
  height: 0.625rem;
  margin-left: 0.3125rem;
  padding: 0.25rem 0.375rem;
}

.title {
  max-width: 11em;
  margin: 0 0 1.25rem;
  display: block;
  font-weight: 600;
  line-height: 1.02;
  text-transform: uppercase;
  color: var(--white);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.subtitle {
  margin: 0 0 2.5rem;
  white-space: pre-line;
  line-height: 1.3;
}

.bottom {
  padding-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed var(--white);
}

.authorBlock {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
  font-size: 0.8125rem;
  text-transform: none;

  p {
    margin: 0;
  }
}

.author {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  img {
    margin-right: 0.625rem;
  }
}

.views {
  display: flex;
  align-items: center;

  svg {
    width: 1.125rem;
    height: 0.875rem;
    margin-right: 0.625rem;
  }
}

.timeRead,
.views {
  height: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--white);
}

.link {
  color: var(--white);
}

.read {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  color: var(--white);
  line-height: 1.3;
}

.brackets {
  max-height: 19rem;
  width: 100%;
  margin: 3.5625rem 0 4.0625rem;
  display: flex;
  justify-content: space-between;
  aspect-ratio: 345/206;

  svg {
    height: 100%;
    width: auto;

    &:last-child {
      transform: rotate(180deg);
    }
  }

  &--big {
    margin: 2.625rem 0 2.375rem;
  }
}

@media (max-width: 85.375rem) and (min-width: 62rem) {
  .title {
    font-size: 2.25rem;
  }
}

@media (max-width: 75rem) and (min-width: 62rem) {
  .bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .read {
    margin: 0 auto;
  }
}

@media (max-width: 62rem) {
  .container {
    min-height: calc(100dvh - 14.8125rem);
    margin: 0 -1rem;
    padding: 1rem;

    &::after {
      top: auto;
      bottom: 0;
      left: 0;
    }
  }

  .image {
    &--desc {
      object-position: top;
    }
  }
}

@media (max-width: 48rem) {
  .all {
    display: none;
  }

  .image {
    &--desc {
      opacity: 0;
    }

    &--mobile {
      opacity: 1;
    }
  }

  .title {
    margin-bottom: 1.25rem;
  }

  .subtitle {
    margin-bottom: 1.875rem;
  }

  .bottom {
    padding-top: 0.625rem;
    display: block;
  }

  .authorBlock {
    margin-bottom: 0.625rem;
    column-gap: 0.9375rem;
    font-size: 0.75rem;
  }

  .timeRead,
  .views {
    padding-left: 0.9375rem;
  }

  .read {
    padding-top: 0.9375rem;
    justify-content: center;
    border-top: 1px dashed var(--white);
  }
}
